import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const threeDButtonBox = ({ theme }: { theme: Theme }) => css`
  width: 30px;
  height: 30px;
  font-weight: 700;
  color: rgb(150, 150, 150);

  // matches mapbox style
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.palette.white.main};
  border-radius: ${theme.shape.borderRadius}px;

  transition: all 0.2s;

  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    background-color: ${theme.palette.white.light};
    color: rgb(0, 0, 0);
  }
`
