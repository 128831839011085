/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { dataGridContainer, filtersContainer, fullRiskSummary } from './FullRiskSummary.styles'
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { fetchFullClientRiskSummary, FullRiskSummaryDataType } from './RiskSummaryFetcher'
import { groupHeader, groupItems } from '../RegionFilter/RegionFilter.styles'
import { tableContainer } from './FullRiskSummary.styles'
import { round } from '../Charts/utils/utils'
import { capitalizeFirstLetter } from '@src/utils/strings.utils'
import { useLayerDataFetcher } from '../SideDrawerContent/data_fetchers/layerDataFetcher'
import { DataGrid, GridCellParams, GridComparatorFn } from '@mui/x-data-grid'
import { Download } from '@mui/icons-material'

const levelOrder = ['insignificant', 'minor', 'moderate', 'major', 'exposed']

export const FullRiskSummary = () => {
  const theme = useTheme()

  const layerData = useLayerDataFetcher()
  const [isLoading, setIsLoading] = useState(false)

  const [selectedElementDomain, setSelectedElementDomain] = useState<string>('all')

  const elementNames = useMemo(() => {
    return layerData
      ? Object.entries(layerData.assetDialogData)
          .flatMap(([categoryKey, category]) =>
            selectedElementDomain == 'all' || selectedElementDomain == categoryKey
              ? category.groups.flatMap((group) => group.assets)
              : [],
          )
          .reduce((acc, asset) => {
            acc[asset.type] = asset.display_name
            return acc
          }, {} as Record<string, string>)
      : {}
  }, [layerData, selectedElementDomain])
  const [hazardLayers, setHazardLayers] = useState<string[]>([])

  const allHazardOptions = useMemo(() => {
    return layerData?.hazardDialogData.hazardDetails
  }, [layerData])
  const allHazardScenarioOptions = useMemo(() => {
    return allHazardOptions?.flatMap((option) => option.scenarios)
  }, [allHazardOptions])

  const [regionsToggle, setRegionsToggle] = useState<'regions' | 'all'>('regions')
  const [valueTypeToggle, setValueTypeToggle] = useState<'metric' | 'replacementCost'>('metric')
  const [showPercent, setShowPercent] = useState(false)

  const getValue = (
    type: 'metric' | 'replacementCost',
    value: { metric: number; replacementCost: number | null },
  ) => {
    return type == 'metric' ? value.metric : value.replacementCost ?? value.metric
  }
  const getTotal = (
    type: 'metric' | 'replacementCost',
    value: { metricTotal: number; replacementCostTotal: number | null },
  ) => {
    return type == 'metric' ? value.metricTotal : value.replacementCostTotal ?? value.metricTotal
  }

  const [cachedRiskSummaryData, setCachedRiskSummaryData] = useState<{
    [key: string]: FullRiskSummaryDataType
  }>({})
  const [riskSummaryData, setRiskSummaryData] = useState<FullRiskSummaryDataType>({})

  const elementExposureLevels = useMemo(() => {
    return Object.values(riskSummaryData).reduce((acc, regionData) => {
      Object.entries(regionData).forEach(([element, elementData]) => {
        const levels = Object.keys(elementData.vulnerabilities).toSorted(
          (a, b) => levelOrder.indexOf(a) - levelOrder.indexOf(b),
        )
        acc[element] = levels
      })
      return acc
    }, {} as Record<string, string[]>)
  }, [riskSummaryData])
  const abortController = useRef(new AbortController())

  useEffect(() => {
    if (!hazardLayers) return
    const cacheKey = hazardLayers.toSorted().join(',')
    if (cachedRiskSummaryData[cacheKey]) {
      setRiskSummaryData(cachedRiskSummaryData[cacheKey])
      return
    }
    const newAbortController = new AbortController()
    abortController.current.abort()
    abortController.current = newAbortController
    setIsLoading(true)
    fetchFullClientRiskSummary(hazardLayers, newAbortController).then((data) => {
      if (data === null) {
        return
      }
      const filteredData = Object.entries(data).reduce((acc, [region, regionData]) => {
        if (Object.keys(regionData).length !== 0) {
          acc[region] = regionData
        }
        return acc
      }, {} as FullRiskSummaryDataType)

      setCachedRiskSummaryData((prev) => ({ ...prev, [cacheKey]: filteredData }))
      setRiskSummaryData(filteredData)
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hazardLayers])

  const regions = useMemo(() => {
    if (riskSummaryData) {
      if (regionsToggle === 'regions') {
        return Object.keys(riskSummaryData)
          .toSorted()
          .filter((region) => region !== 'All')
      } else if (regionsToggle === 'all') {
        return ['All']
      }
    }
    return []
  }, [regionsToggle, riskSummaryData])

  const elements = useMemo(
    () =>
      riskSummaryData && regions.length > 0
        ? Object.values(riskSummaryData)
            .reduce((acc, regionData) => {
              for (const element in regionData) {
                if (!acc.includes(element) && elementExposureLevels[element].length > 0) {
                  acc.push(element)
                }
              }
              return acc
            }, [] as string[])
            .toSorted((a, b) => (elementNames[a] < elementNames[b] ? -1 : 1))
        : [],
    [riskSummaryData, regions.length, elementExposureLevels, elementNames],
  )

  const elementDomains = useMemo(
    () =>
      layerData
        ? Object.entries(layerData.assetDialogData).reduce((acc, [categoryKey, category]) => {
            if (category.groups.some((g) => g.assets.some((a) => elements.includes(a.type)))) {
              acc[categoryKey] = category.groups.flatMap((g) => g.assets.map((a) => a.type))
            }
            return acc
          }, {} as { [key: string]: string[] })
        : {},
    [elements, layerData],
  )

  const elementUnits = useMemo(() => {
    return Object.values(riskSummaryData).reduce((acc, regionalData) => {
      if (valueTypeToggle == 'metric')
        Object.entries(regionalData).forEach(
          ([elementType, { metricUnit }]) =>
            (acc[elementType] = ['km', 'ha', 'cm'].includes(metricUnit) ? metricUnit : 'each'),
        )
      else
        Object.entries(regionalData).forEach(([elementType, elementData]) => {
          if (
            Object.values(elementData.vulnerabilities).some(
              (vulnerability) => vulnerability.replacementCost !== null,
            )
          )
            acc[elementType] = '$'
          else
            acc[elementType] = ['km', 'ha', 'cm'].includes(elementData.metricUnit)
              ? elementData.metricUnit
              : 'each'
        })

      return acc
    }, {} as { [elementType: string]: string })
  }, [riskSummaryData, valueTypeToggle])

  const columnGroupingModel = useMemo(
    () =>
      elements.map((element) => ({
        groupId: element,
        headerName:
          elementNames[element] + (elementUnits[element] ? ' (' + elementUnits[element] + ')' : ''),
        children: [
          ...elementExposureLevels[element].map((level) => ({
            field: `${element}|${level}`,
          })),
          { field: `${element}|total` },
        ],
      })),
    [elements, elementNames, elementUnits, elementExposureLevels],
  )

  const customSortComparator: GridComparatorFn = (v1, v2, cellParams1, cellParams2) => {
    if (cellParams1.id === 'All') return -1
    if (cellParams2.id === 'All') return 1
    if (v1 === '-') v1 = -Infinity
    if (v2 === '-') v2 = -Infinity
    if (typeof v1 === 'string') v1 = parseFloat(v1)
    if (typeof v2 === 'string') v2 = parseFloat(v2)

    return v1 > v2 ? -1 : 1
  }
  const columnRanges = useMemo(() => {
    return elements.flatMap((element) => {
      const maxTotal = getTotal(valueTypeToggle, riskSummaryData.All[element])
      return [
        ...elementExposureLevels[element].map((level) => {
          let max = getValue(valueTypeToggle, riskSummaryData.All[element].vulnerabilities[level])
          if (showPercent) max = (max * 100) / maxTotal

          return {
            field: `${element}|${level}`,
            max,
          }
        }),
        {
          field: `${element}|total`,
          max: maxTotal,
        },
      ]
    })
  }, [elements, valueTypeToggle, riskSummaryData.All, elementExposureLevels, showPercent])

  const columns = useMemo(
    () => [
      { field: 'region', headerName: 'Region', width: 150 },
      ...elements.flatMap((element) =>
        selectedElementDomain === 'all' || elementDomains[selectedElementDomain]?.includes(element)
          ? [
              ...elementExposureLevels[element].map((level, level_i) => ({
                field: `${element}|${level}`,
                headerName: `${capitalizeFirstLetter(level)}${showPercent ? ' (%)' : ''}`,
                sortComparator: customSortComparator,
                type: 'number' as const,
                flex: 1,
                minWidth: 88,
                max: columnRanges.find((range) => range.field === `${element}|${level}`)?.max,
                isFirstInGroup: level_i === 0,
                cellClassName: (params: GridCellParams) => {
                  let classes = ''
                  if ((params.colDef as unknown as { isFirstInGroup: boolean }).isFirstInGroup)
                    classes += 'first-in-group'
                  return classes
                },
              })),
              {
                field: `${element}|total`,
                headerName: 'Total',
                minWidth: 88,
                flex: 1,
                sortComparator: customSortComparator,
                type: 'number' as const,
                max: columnRanges.find((range) => range.field === `${element}|total`)?.max,
              },
            ]
          : [],
      ),
    ],
    [
      elements,
      selectedElementDomain,
      elementDomains,
      elementExposureLevels,
      columnRanges,
      showPercent,
    ],
  )

  const rows = useMemo(
    () =>
      regions.map((region) => {
        const row: Record<string, string | number> = {
          region,
          id: region,
        }
        elements.forEach((element) => {
          elementExposureLevels[element].forEach((level) => {
            let value: string | number = ''
            if (
              riskSummaryData[region][element] &&
              riskSummaryData[region][element].vulnerabilities[level]
            ) {
              value = getValue(
                valueTypeToggle,
                riskSummaryData[region][element].vulnerabilities[level],
              )

              if (value == 0) {
                row[`${element}|${level}`] = ''
                return
              }

              if (showPercent)
                value = (value * 100) / getTotal(valueTypeToggle, riskSummaryData[region][element])

              if (value <= 0.1) {
                value = round(value, 4)
              } else value = round(value, 2)
            }
            row[`${element}|${level}`] = value
          })
          if (riskSummaryData[region][element]) {
            const total = getTotal(valueTypeToggle, riskSummaryData[region][element])
            row[`${element}|total`] = total
          } else row[`${element}|total`] = ''
        })
        return row
      }),
    [regions, elements, elementExposureLevels, riskSummaryData, valueTypeToggle, showPercent],
  )

  const downloadCSV = () => {
    const consequenceLookUp: { [key: string]: string } = {}
    columns.forEach((column) => {
      consequenceLookUp[column.field] = column.headerName
    })

    const csvData = rows.map((row) => {
      const newRow: Record<string, string> = {}
      for (const key in row) {
        if (key === 'id') continue
        newRow[key] = row[key].toString()
      }
      return newRow
    })

    const csv =
      '\ufeff' + // UTF-8 byte-order-mark
      [
        Object.keys(csvData[0])
          .map((elementTypeConsequence) => {
            const elementType = elementTypeConsequence.split('|')[0]
            return (
              (elementNames[elementType] ?? '') +
              (elementUnits[elementType] ? ' (' + elementUnits[elementType] + ')' : '')
            )
          })
          .join(','),
        Object.keys(csvData[0])
          .map((elementTypeConsequence) => {
            return consequenceLookUp[elementTypeConsequence] ?? ''
          })
          .join(','),
        ...csvData.map((row) =>
          Object.values(row)
            .map((v) => (v == '-' ? '' : v))
            .join(','),
        ),
      ].join('\n')

    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')

    a.href = url
    a.download = 'risk_summary.csv'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <Box css={fullRiskSummary({ theme })}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          color="#0b2948"
          fontSize="16px"
          marginBottom="16px"
          marginTop="8px"
          fontWeight="600"
        >
          Summary of Asset Risk to Regions
        </Typography>
        {elements.length > 0 && !isLoading && (
          <Button
            variant="outlined"
            sx={{
              color: '#0b2948',
              borderColor: '#d4d9de',
              padding: '4px 16px 4px 8px',
              gap: '4px',
              '&:hover': {
                borderColor: '#d4d9de',
                backgroundColor: '#f0f3f4',
              },
            }}
            size="small"
            onClick={downloadCSV}
          >
            <Download sx={{ height: '18px' }} />
            Download CSV
          </Button>
        )}
      </Box>
      <Autocomplete
        sx={{ width: '100%', minWidth: '240px' }}
        value={
          allHazardScenarioOptions?.filter((option) => hazardLayers.includes(option.assetTag)) ?? []
        }
        isOptionEqualToValue={(option, value) => option.assetTag === value.assetTag}
        size="small"
        fullWidth
        multiple
        disableCloseOnSelect
        limitTags={2}
        options={allHazardScenarioOptions ?? []}
        groupBy={(option) => option.hazard_name ?? ''}
        getOptionLabel={(option) => option.display_name}
        getOptionKey={(option) => option.assetTag}
        onChange={(_event, values) => {
          setHazardLayers(values.map((value) => value.assetTag))
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            key="hazard-layer-filter"
            placeholder={hazardLayers.length == 0 ? 'Choose hazard scenarios' : ''}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <div css={groupHeader({ theme })}>{params.group}</div>
            <ul css={groupItems({ theme })}>{params.children}</ul>
          </li>
        )}
      />
      <Box css={filtersContainer({ theme })}>
        <FormGroup row>
          <Typography>Element group:</Typography>
          <Select
            value={selectedElementDomain}
            onChange={(event) => {
              setSelectedElementDomain(event.target.value as string)
            }}
          >
            <MenuItem value={'all'}>All</MenuItem>
            {Object.keys(elementDomains)
              .toSorted()
              .map((categoryKey) => (
                <MenuItem key={categoryKey} value={categoryKey}>
                  {categoryKey}
                </MenuItem>
              ))}
          </Select>
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch checked={showPercent} onChange={() => setShowPercent((prev) => !prev)} />
            }
            label="Percent"
          />
        </FormGroup>
        <FormGroup row>
          <Typography>Show:</Typography>
          <ToggleButtonGroup
            value={valueTypeToggle}
            exclusive
            size="small"
            onChange={(_event, value) => {
              if (value) setValueTypeToggle(value)
            }}
          >
            <ToggleButton key={'metric'} value={'metric'}>
              Quantity
            </ToggleButton>
            <ToggleButton key={'replacementCost'} value={'replacementCost'}>
              Value (where available)
            </ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
        <FormGroup row>
          <Typography>Show exposure:</Typography>
          <ToggleButtonGroup
            value={regionsToggle}
            exclusive
            size="small"
            onChange={(_event, value) => {
              if (value) setRegionsToggle(value)
            }}
          >
            <ToggleButton key={'regions'} value={'regions'}>
              Per Region
            </ToggleButton>
            <ToggleButton key={'all'} value={'all'}>
              For All Regions
            </ToggleButton>
          </ToggleButtonGroup>
        </FormGroup>
      </Box>
      <Box css={tableContainer({ theme })}>
        {elements.length > 0 || isLoading ? (
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <DataGrid
              columnGroupingModel={columnGroupingModel}
              rows={rows}
              columns={columns}
              loading={isLoading}
              css={dataGridContainer({ theme })}
              rowHeight={32}
              columnHeaderHeight={32}
              disableColumnResize={false}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              border: '1px solid #e0e0e0',
              backgroundColor: '#f8f8f8',
              borderRadius: '4px',
              color: '#888',
            }}
          >
            Choose hazard scenarios above to view risk to all elements
          </Box>
        )}
      </Box>
    </Box>
  )
}
