/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'
import 'mapbox-gl/dist/mapbox-gl.css'

import { BaseMapButton } from './BaseMapButton'
import {
  mapBottomLeftContainer,
  mapBottomRightContainer,
  mapOverlayContainer,
  mapTopRightContainer,
} from './RiskMap.styles'
import { MapTools } from './MapTools/MapTools'
import { useMemo, useRef } from 'react'
import { MapLegends } from './MapLegends'
import { GeocoderButton } from '@src/components/Atoms/Geocoder'
import { RiskMapView } from '@src/components/Molecules/MapView/RiskMapView'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { ThreeDButton } from '@src/components/Molecules/ThreeDButton/ThreeDButton'

export const RiskMap = () => {
  const theme = useTheme()
  const { tab } = useSelector((state: RootState) => state.sideDrawer)

  const mapOverlayRef = useRef<HTMLDivElement>(null)
  const geocoderEnabled = useMemo(() => tab !== 'Stories', [tab])

  return (
    <>
      <RiskMapView />

      <Box ref={mapOverlayRef} css={mapOverlayContainer}>
        <Box css={mapTopRightContainer}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '2px' }}
          >
            <MapTools mapOverlayRef={mapOverlayRef} />
            {geocoderEnabled && <GeocoderButton />}
            <ThreeDButton />
          </Box>
        </Box>
        <Box css={mapBottomLeftContainer}>
          <BaseMapButton />
        </Box>
        <Box css={mapBottomRightContainer({ theme })}>
          <MapLegends />
        </Box>
        <Box className="tutorial-map" />
      </Box>
    </>
  )
}
