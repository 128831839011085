/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'
import { RiskMap } from '@pages/Map'

import { setSideDrawerIsOpen, setSideDrawerTab } from '@redux/sideDrawer/sideDrawerSlice'
import { RootState } from '@redux/store'
import { SideDrawerContent } from '@src/components/Molecules/SideDrawerContent'
import { MapboxOptions } from 'mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import { riskPageContainer, sideDrawerContainer, sideDrawerFab } from './RiskPage.styles'
import { useEffect, useState } from 'react'
import { useMap } from '@contexts/MapContext'
import { SideDrawer } from '@src/components/Molecules/SideDrawer'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { useStoriesFetcher } from '@src/components/Molecules/SideDrawerContent/data_fetchers/storiesFetcher'
import { Story } from '@src/components/Molecules/SideDrawerContent/tabs/StoriesTab/Stories.utils'
import { Add } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { RiskPagePlaceholder } from './RiskPagePlaceholder'

export type ViewOrientation = Pick<
  MapboxOptions,
  'center' | 'zoom' | 'pitch' | 'maxPitch' | 'zoom' | 'bearing'
>

export type sideDrawerTab = 'Risk' | 'Stories' | 'Welcome & FAQ'

export const RiskPage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const { setMapPadding, flyTo, map, setShowAddLayersModalTab, getMapPadding } = useMap()

  const { clientWelcomeSidebarContent, clientName } = useSelector((state: RootState) => state.user)
  const { isOpen, tab } = useSelector((state: RootState) => state.sideDrawer)

  const { features } = useFeatureFlags()
  const riskModuleIsInDevelopment = features.find(
    (feature) => feature.feature == 'riskModuleIsInDevelopment' && feature.enabled,
  )

  const [tabIcons, setTabIcon] = useState<string[]>(['Info'])
  const [riskTabs, setRiskTabs] = useState<sideDrawerTab[]>(['Risk'])

  const stories: Story[] | null = useStoriesFetcher()

  // Set the tabs based on available data
  useEffect(() => {
    const newRiskTabs: sideDrawerTab[] = []
    const newTabIcons = []

    if (clientName !== undefined && clientName !== '') {
      newRiskTabs.push('Risk')
      newTabIcons.push('s3://map-info.svg')
    }

    if (
      features.find((feature) => feature.feature == 'riskStories' && feature.enabled) &&
      stories &&
      stories.length > 0
    ) {
      newRiskTabs.push('Stories')
      newTabIcons.push('s3://openbook.svg')
    }

    setRiskTabs(newRiskTabs)
    setTabIcon(newTabIcons)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWelcomeSidebarContent, clientName, features, stories])

  // Open the side drawer by default
  // and close it when logged out
  useEffect(() => {
    if (clientName && isOpen !== true) dispatch(setSideDrawerIsOpen(true))
    if (!clientName) dispatch(setSideDrawerIsOpen(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName])

  // Hide side drawer when entering full screen
  useEffect(() => {
    function onEnterFullscreen() {
      dispatch(setSideDrawerIsOpen(false))
    }

    document.addEventListener('EnteringFullScreen', onEnterFullscreen)

    return () => document.removeEventListener('EnteringFullScreen', onEnterFullscreen)
  }, [dispatch])

  if (riskModuleIsInDevelopment) {
    return <RiskPagePlaceholder />
  }

  return (
    <Box css={riskPageContainer} id="riskPageContainer">
      <Box css={sideDrawerContainer}>
        {riskTabs.length > 0 && (
          <SideDrawer
            isOpen={isOpen}
            handleToggle={() => {
              dispatch(setSideDrawerIsOpen(!isOpen))
            }}
            initialWidth={600}
            tabIcons={tabIcons}
            setTab={(newtab) => {
              if (newtab !== tab) dispatch(setSideDrawerTab(newtab as sideDrawerTab))
            }}
            tab={tab}
            tabs={riskTabs}
            handleWidthChange={(width: number) => {
              const { left: previousWidth } = getMapPadding(true)
              setMapPadding({ top: 0, left: width, bottom: 0, right: 0 })

              if (map && width != previousWidth) {
                flyTo({
                  latitude: map.getCenter().lat,
                  longitude: map.getCenter().lng,
                  zoom: map.getZoom(),
                  usePadding: true,
                  duration: 800,
                })
              }
            }}
          >
            <SideDrawerContent />
            {tab !== 'Stories' && (
              <Tooltip title="Add layers" placement="right">
                <Button
                  css={sideDrawerFab({ theme })}
                  size="small"
                  onClick={() => setShowAddLayersModalTab('elements')}
                  aria-label="Add Layers"
                  className="tutorial-add-layers-button"
                >
                  <Add fontSize="medium" />
                </Button>
              </Tooltip>
            )}
          </SideDrawer>
        )}
      </Box>

      <RiskMap />
    </Box>
  )
}
