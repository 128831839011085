// As this component is only used in a mapbox tooltip it cannot use emotion CSS. All styles need to be manually added to each component.

import SecurityIcon from '@mui/icons-material/Security'
import { Box, Divider, Typography } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import { Solver } from '@src/utils/IconColourer'
import { firstLetterToUpperCase, generateS3Url } from '@src/utils/strings.utils'

import './MapTooltip.styles.css'

export interface MapTooltipProps {
  asset?: {
    id: number
    icon: Icon
    name: string
    type: string
    sentence: string
    vulnerabilityLabel?: string
    vulnerabilityColour?: string
    exposure?: string
    exposureUnit?: string
  }
  hazards?: {
    title: string
    hazard_id?: string
    sentence: string
    vulnerabilityColour: string
    exposure_value: string
    metric: number
    unit: string
    vulnerabilityLabel: string
  }[]
  information?: {
    id: number
    title: string
    icon?: Icon
    value?: string
  }
  mainColour: string
  prefer_hazard_term: boolean
  prefer_risk_term: boolean
  knownRiskIcons: string[]
}

/**
 * A tooltip component for displaying information about a map feature
 * Returns null if there is no asset sentence, no information value, and no hazards
 */
export const MapTooltip = ({
  asset,
  hazards,
  information,
  mainColour: primaryColour,
  prefer_hazard_term,
  prefer_risk_term,
  knownRiskIcons,
}: MapTooltipProps) => {
  // React hooks and emotion CSS are not allowed in this file - MAPBOX

  const iconColourSolver = new Solver(primaryColour)
  const iconColourSolution = iconColourSolver.solve()

  const has_hazards = hazards && hazards.length > 0 && hazards[0] !== undefined
  return has_hazards || asset || information ? (
    <Box
      className="tooltip-container"
      style={{
        padding: '4px 0px 4px 0px',
        backgroundColor: 'white',
        borderRadius: '4px',
        position: 'relative',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
      }}
    >
      {asset && (
        <>
          <Box
            component="span"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '6px',
              height: '100%',
              backgroundColor: asset.vulnerabilityColour,
              borderRadius: '4px 0 0 4px',
            }}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4px 16px',
            }}
          >
            <Box
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px' }}
            >
              {asset.icon.includes('s3://') ? (
                <img
                  src={generateS3Url(asset.icon)}
                  style={{ width: '24px', height: '24px', filter: iconColourSolution.filter }}
                />
              ) : (
                asset.icon && (
                  <Icon iconName={firstLetterToUpperCase(asset.icon) as Icon} size="large" />
                )
              )}
              <Typography id="tooltip-title">{asset.name}</Typography>
            </Box>
            <Box
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '4px' }}
            >
              <Typography>ID</Typography>
              <Typography>{asset.id}</Typography>
            </Box>
          </Box>
          <Divider light />
          {asset.sentence ? (
            <Box style={{ padding: '8px 16px 8px 16px', textOverflow: 'ellipsis' }}>
              <div id="asset-sentence" dangerouslySetInnerHTML={{ __html: asset.sentence }} />
            </Box>
          ) : (
            <Box style={{ padding: '8px 16px 8px 16px', fontStyle: 'italic' }}>
              <Typography>Additional information not available.</Typography>
            </Box>
          )}
        </>
      )}

      {information && (
        <>
          <Box
            component="span"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '6px',
              height: '100%',
              borderRadius: '4px 0 0 4px',
            }}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '4px 16px',
            }}
          >
            <Box
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px' }}
            >
              {information.icon && (
                <Icon iconName={firstLetterToUpperCase(information.icon) as Icon} size="large" />
              )}
              <Typography id="tooltip-title">{information.title}</Typography>
            </Box>
            <Box
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '4px' }}
            >
              <Typography>ID</Typography>
              <Typography>{information.id}</Typography>
            </Box>
          </Box>
          <Divider light />
          {information.value ? (
            <Box id="information-container" style={{ padding: '12px 16px 12px 16px' }}>
              <div dangerouslySetInnerHTML={{ __html: information.value }} />
            </Box>
          ) : (
            <Box style={{ padding: '12px 16px 0 16px', fontStyle: 'italic' }}>
              <Typography>Additional information not available.</Typography>
            </Box>
          )}
        </>
      )}

      {has_hazards && (
        <>
          <Divider light />
          <Box
            style={{
              padding: '8px 16px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '8px',
                padding: '4px 0',
              }}
            >
              <Icon iconName="Warning" size="medium" />
              <Typography variant="subtitle2" fontWeight={700}>
                {prefer_hazard_term ? 'Hazards' : 'Risk Sources'}
              </Typography>
            </Box>
            <Box
              id="hazard-container"
              style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '4px 0' }}
            >
              {hazards.map(
                (hazard, index) =>
                  hazard && (
                    <Box
                      key={hazard.title + index}
                      style={{
                        border: '1px solid #e7e7e7',
                        borderRadius: '4px',
                        padding: '8px 8px 12px 18px',
                        position: 'relative',
                      }}
                    >
                      <Box
                        component="span"
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          width: '6px',
                          height: '100%',
                          backgroundColor: hazard.vulnerabilityColour,
                          borderRadius: '4px 0 0 4px',
                        }}
                      />
                      <Typography variant="subtitle2">{hazard.title}</Typography>
                      <div
                        id="hazard-sentence"
                        dangerouslySetInnerHTML={{ __html: hazard.sentence }}
                      />
                      <Divider light />
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                          marginTop: '4px',
                          gap: '16px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '4px',
                          }}
                        >
                          <Typography>{prefer_risk_term ? 'Risk' : 'Consequence'}:</Typography>
                          <Typography id="single-vulnerability-value">
                            {hazard.vulnerabilityLabel}
                          </Typography>
                        </Box>
                        {hazard.exposure_value !== null && (
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              gap: '4px',
                            }}
                          >
                            <Typography>Exposure:</Typography>
                            <Typography id="single-exposure-value">
                              {hazard.exposure_value} {hazard.unit ?? ''}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ),
              )}
            </Box>
          </Box>
        </>
      )}

      {knownRiskIcons.length > 0 && (
        <>
          <Divider />
          <Box
            style={{
              padding: '8px 16px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '8px',
                padding: '4px 0',
              }}
            >
              <Icon iconName="Warning" size="medium" />
              <Typography variant="subtitle2" fontWeight={700}>
                Known {prefer_hazard_term ? 'hazards' : 'risks'}
              </Typography>
            </Box>
            <Box
              id="known-risks-icon-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                margin: '4px 0',
              }}
            >
              {knownRiskIcons.map((knownRiskIcon) => (
                <Icon key={knownRiskIcon} iconName={knownRiskIcon} size="medium" />
              ))}{' '}
            </Box>
          </Box>
        </>
      )}

      {has_hazards && (
        <>
          <Divider light />
          <Box
            id="tooltip-footer"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '22px',
              padding: '8px 16px 4px 16px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '1',
                gap: '4px',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: '4px',
                }}
              >
                <SecurityIcon style={{ color: '#0B2948' }} />
                <Typography variant="subtitle2">
                  {prefer_risk_term ? 'Risk' : 'Consequence'}
                </Typography>
              </Box>
              <Typography id="vulnerability-label" variant="subtitle2" fontWeight={700}>
                {asset?.vulnerabilityLabel}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  ) : null
}
