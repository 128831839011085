/** @jsxImportSource @emotion/react */
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { useEffect, useState } from 'react'
import { Box, Tooltip, useTheme } from '@mui/material'
import { useMap } from '@contexts/MapContext'
import { threeDButtonBox } from './ThreeDButton.styles'

export const ThreeDButton = () => {
  const { map, flyTo } = useMap()
  const theme = useTheme()
  const [is3D, setIs3D] = useState(false)

  const handleButtonClick = () => {
    flyTo({ pitch: is3D ? 0 : 75, duration: 800, usePadding: true })
    setIs3D(!is3D)
  }

  useEffect(() => {
    map?.on('pitchend', (_) => {
      if (map.getPitch() == 0) setIs3D(false)
      else setIs3D(true)
    })
  }, [map])

  return (
    <Box sx={{ marginTop: theme.spacing(1), display: 'flex', flexDirection: 'row' }}>
      <Tooltip arrow title="Ctrl + mouse to tilt map">
        <Box css={threeDButtonBox({ theme })} onClick={handleButtonClick}>
          {is3D ? '2D' : '3D'}
        </Box>
      </Tooltip>
    </Box>
  )
}
