import { PaletteColor } from '@mui/material/styles'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { theme } from '@uintel/ui-component-library'

export interface UserTheme {
  primary: PaletteColor
  secondary: PaletteColor
}

export interface UserLogos {
  simple: string
  full: string
  whiteSimple: string
  whiteFull: string
}

export interface Client {
  client_name: string
  display_name: string
  accepted_tsncs: boolean
}

export interface ClientWelcomeSidebarContent {
  title?: string
  subtitle?: string
  faq?: {
    q: string
    a: string
  }[]
}

export interface ClientTerrainMap {
  tile_url: string
  exaggeration: number
  maxzoom?: number
  tileSize?: number
}

export interface GetUserResponse {
  user_role: string
  user_uuid: string
  client_name: string
  accepted_tsncs: boolean
  email: string
  date_created: string
  status: string
  last_logged_in: string
  logged_in_count: number
  show_changelog: boolean
  clientTerrainMap?: ClientTerrainMap
}

export interface UserState {
  theme: UserTheme
  availableClients: Client[]
  clientTerrainMap?: ClientTerrainMap
  clientDisplayName: string
  clientName: string
  userRole: string
  userEmail: string
  logos: UserLogos
  feedback_url: string
  methods_report_url: string
  tsncs_html: string | null
  accepted_tsncs: boolean
  show_changelog: boolean
  show_feedback_modal: boolean
  clientWelcomeSidebarContent: ClientWelcomeSidebarContent
}

export const defaultLogos: UserLogos = {
  full: PUBLIC_ASSETS_BASE_URL + `/logos/openaccess/logo-full.png`,
  simple: PUBLIC_ASSETS_BASE_URL + `/logos/openaccess/logo-simple.png`,
  whiteFull: PUBLIC_ASSETS_BASE_URL + `/logos/openaccess/logo-white-full.png`,
  whiteSimple: PUBLIC_ASSETS_BASE_URL + `/logos/openaccess/logo-white-simple.png`,
}

export const userInitialState: UserState = {
  availableClients: [],
  clientDisplayName: '',
  clientName: '',
  clientTerrainMap: undefined,
  userRole: '',
  userEmail: '',
  theme: {
    primary: theme.palette.primary,
    secondary: theme.palette.secondary,
  },
  feedback_url: '',
  methods_report_url: '',
  logos: defaultLogos,
  tsncs_html: null,
  accepted_tsncs: false,
  show_changelog: false,
  show_feedback_modal: false,
  clientWelcomeSidebarContent: {},
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: userInitialState,
  reducers: {
    updateUserStateProperty: (state, action: PayloadAction<Partial<UserState>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setupUserState: (state, action: PayloadAction<Partial<UserState>>) => {
      let logo_client_name =
        action.payload.clientName !== undefined ? action.payload.clientName : state.clientName
      if (logo_client_name.endsWith('_old')) {
        logo_client_name = logo_client_name.replace('_old', '')
      }
      if (!logo_client_name) {
        logo_client_name = 'openaccess'
      }
      return {
        ...state,
        ...action.payload,
        logos: {
          full: `https://d2ufl17mx4sema.cloudfront.net/public/logos/${logo_client_name}/logo-full.png`,
          simple: `https://d2ufl17mx4sema.cloudfront.net/public/logos/${logo_client_name}/logo-simple.png`,
          whiteFull: `https://d2ufl17mx4sema.cloudfront.net/public/logos/${logo_client_name}/logo-white-full.png`,
          whiteSimple: `https://d2ufl17mx4sema.cloudfront.net/public/logos/${logo_client_name}/logo-white-simple.png`,
        },
      }
    },
    setUserTheme: (state, action: PayloadAction<UserState['theme']>) => {
      return {
        ...state,
        theme: action.payload,
      }
    },
    setUserClientDisplayName: (state, action: PayloadAction<UserState['clientDisplayName']>) => {
      if (action.payload === state.clientDisplayName) return state
      return {
        ...state,
        clientDisplayName: action.payload,
      }
    },
    setUserAvailableClients: (state, action: PayloadAction<UserState['availableClients']>) => {
      return {
        ...state,
        availableClients: action.payload,
      }
    },
    setUserClientName: (state, action: PayloadAction<UserState['clientName']>) => {
      if (action.payload === state.clientName) return state
      return {
        ...state,
        clientName: action.payload,
      }
    },
    setUserFeedbackUrl: (state, action: PayloadAction<UserState['feedback_url']>) => {
      if (action.payload === state.feedback_url) return state
      return {
        ...state,
        feedback_url: action.payload,
      }
    },
    setUserLogos: (state, action: PayloadAction<UserState['logos']>) => {
      return {
        ...state,
        logos: action.payload,
      }
    },
    setUserTsncsHtml: (state, action: PayloadAction<UserState['tsncs_html']>) => {
      if (action.payload === state.tsncs_html) return state
      return {
        ...state,
        tsncs_html: action.payload,
      }
    },
    setUserAcceptedTsncs: (state, action: PayloadAction<UserState['accepted_tsncs']>) => {
      if (action.payload === state.accepted_tsncs) return state
      return {
        ...state,
        accepted_tsncs: action.payload,
      }
    },
    setUserRole: (state, action: PayloadAction<UserState['userRole']>) => {
      if (action.payload === state.userRole) return state
      return {
        ...state,
        userRole: action.payload,
      }
    },
    setShowChangelog: (state, action: PayloadAction<UserState['show_changelog']>) => {
      if (action.payload === state.show_changelog) return state
      return {
        ...state,
        show_changelog: action.payload,
      }
    },
    setUserEmail: (state, action: PayloadAction<UserState['userEmail']>) => {
      return {
        ...state,
        userEmail: action.payload,
      }
    },
    setUserClientWelcomeSidebarContent: (
      state,
      action: PayloadAction<UserState['clientWelcomeSidebarContent']>,
    ) => {
      return {
        ...state,
        clientWelcomeSidebarContent: action.payload,
      }
    },
  },
})

export const {
  setUserTheme,
  setupUserState,
  setUserClientDisplayName,
  setUserClientName,
  setUserRole,
  setUserEmail,
  setUserFeedbackUrl,
  setUserLogos,
  updateUserStateProperty,
  setUserTsncsHtml,
  setUserAcceptedTsncs,
  setShowChangelog,
  setUserClientWelcomeSidebarContent,
  setUserAvailableClients,
} = userSlice.actions
export const userReducer = userSlice.reducer
