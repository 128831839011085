import { MAX_CHART_DECIMALS, cleanFloatingPointErrors, roundForDisplay } from './utils'

export class AxisCalculator {
  domain: [number, number]
  decimal_places: number
  tickCount: number
  numbers: number[]
  maxNumber: number

  constructor(numbers: number[], maxTicks = 6) {
    numbers = numbers.map((num) => cleanFloatingPointErrors(num))

    const decimals = numbers.map((num) => {
      const parts = num.toString().split('.')
      return parts.length > 1 ? parts[1].length : 0
    })
    this.numbers = numbers
    this.maxNumber = Math.max(...this.numbers)
    this.decimal_places = Math.min(MAX_CHART_DECIMALS, Math.max(...decimals))

    const { min, max, bucketSize } = roundOutBounds(numbers, maxTicks)
    this.domain = [min, max]

    this.tickCount = this.maxNumber > 0.001 ? Math.ceil((max - min) / bucketSize) + 1 : 2
  }
  roundValue = (value: number, unit = '') => {
    return roundForDisplay(value, unit, this.maxNumber)
  }

  roundValueForDisplay = (value: number) => {
    return this.roundValue(value)
  }
}

const roundOutBounds = (numbers: number[], maximumBuckets = 6) => {
  const min = Math.min(...numbers)
  const max = Math.max(...numbers)
  const minBucketSize = (max - min) / maximumBuckets
  let bucketSize = minBucketSize

  for (let exponent = -8; exponent < 16; exponent++) {
    const power = 10 ** exponent

    for (const friendlyMultiple of [1, 2, 5]) {
      if (minBucketSize < power * friendlyMultiple) {
        bucketSize = power * friendlyMultiple
        break
      }
    }

    if (bucketSize !== minBucketSize) {
      break
    }
  }

  const minBound = Math.floor(min / bucketSize) * bucketSize
  const maxBound = Math.ceil(max / bucketSize) * bucketSize
  return { min: minBound, max: maxBound, bucketSize }
}
