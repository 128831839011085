/** @jsxImportSource @emotion/react */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { BASE_API_URL } from '@src/app-constants'
import { SignInModal } from '@src/components/Organisms/Modals/SignInModal'
import axios from '@src/utils/customAxios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  setUserClientWelcomeSidebarContent,
  setupUserState,
  userInitialState,
} from '@src/redux/user/userSlice'
import { useDispatch } from 'react-redux'
import { mapInitialState, updateMapStateProperty } from '@redux/map/mapSlice'
import { AppInitialLocation, useMap } from '@contexts/MapContext'
import { useSnackbars } from '@uintel/ui-component-library'
import { useNavigate } from 'react-router-dom'

interface AppAuthHolderProps {
  open: boolean
  showLogoutDialog: boolean
  handleLogoutDialogClose: () => void
  handleClose: () => void
  handleOpen: () => void
}

export const AppAuthHolder = ({
  open,
  showLogoutDialog,
  handleLogoutDialogClose,
  handleClose,
  handleOpen,
}: AppAuthHolderProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { map, clearMap, updateLayers, updateDrawAreas, handleSetClientLocation } = useMap()
  const { displaySnackbar } = useSnackbars()

  const [isAuth, setIsAuth] = useState(false)
  const [cookies, , removeCookie] = useCookies(['client_name', 'explorerApiSession'])

  // this is a global interceptor for our custom axios, it's used here to call the react functions
  // it's a ref to avoid re-creating the interceptor on every render
  const authInterceptor = useRef<number | null>(null)

  const handleLogout = async () => {
    /* Update map with provider */
    updateLayers({ layers: [] })
    updateDrawAreas({ drawAreas: [] })
    if (map) {
      clearMap({ map })
    }

    navigate('home')

    setIsAuth(false)
    removeCookie('explorerApiSession')
    Sentry.setUser(null)
    dispatch(setUserClientWelcomeSidebarContent({}))

    /* Update cookie */
    removeCookie('client_name')

    /* Update redux user */
    dispatch(
      setupUserState({
        clientDisplayName: userInitialState.clientDisplayName,
        clientName: userInitialState.clientName,
        feedback_url: userInitialState.feedback_url,
        logos: userInitialState.logos,
        theme: userInitialState.theme,
        accepted_tsncs: userInitialState.accepted_tsncs,
        methods_report_url: userInitialState.methods_report_url,
        tsncs_html: userInitialState.tsncs_html,
      }),
    )
    dispatch(updateMapStateProperty(mapInitialState))
    handleSetClientLocation(AppInitialLocation)
  }

  const handleExpiredSession = useCallback(async () => {
    handleLogout()
    displaySnackbar({
      message: 'Session expired, please log in again',
      type: 'warning',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySnackbar])

  const handleSignout = () => {
    handleLogoutDialogClose()
    handleLogout()
  }

  useEffect(() => {
    if (authInterceptor.current === null) {
      authInterceptor.current = axios.interceptors.response.use(
        (res) => res,
        (err) => {
          if (err?.response?.data?.message === 'Session expired') {
            handleExpiredSession()
            setIsAuth(false)
            handleOpen()
          }
          // this needs to return a rejection, otherwise it doesn't get caught!
          if (err) return Promise.reject(err)
        },
      )
    }
  }, [handleExpiredSession, handleOpen, setIsAuth])

  // check if a session exists and pass a flag down to the modal
  useEffect(() => {
    if (
      cookies['client_name'] &&
      cookies['client_name'] !== 'openaccess' &&
      cookies['explorerApiSession']
    ) {
      axios
        .get(`${BASE_API_URL}/api/user/session`)
        .then(() => {
          setIsAuth(true)
        })
        .catch(() => {
          setIsAuth(false)
        })
    }
    // note that the cookies are NOT reactive via set-cookie from a header, so we need to use the axios interceptor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      {open ? (
        <SignInModal
          handleClose={handleClose}
          open={open && !isAuth}
          isAuth={isAuth}
          setIsAuth={setIsAuth}
          handleLogout={handleLogout}
        />
      ) : null}
      {showLogoutDialog ? (
        <Dialog
          open={showLogoutDialog}
          onClose={() => handleLogoutDialogClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sign out</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to continue signing out?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleLogoutDialogClose()}>Cancel</Button>
            <Button onClick={() => handleSignout()} autoFocus>
              Signout
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}
