import { getCenterOfFeature } from '@src/components/Molecules/MapView/RiskMapView.utilities'
import { LngLat, Map, MapboxGeoJSONFeature } from 'mapbox-gl'

export interface FlyTo {
  latitude?: number
  longitude?: number
  essential?: boolean
  zoom?: number
  pitch?: number
  bearing?: number
  duration?: number
  padding?: { top: number; right: number; bottom: number; left: number }
  usePadding?: boolean
}

export interface FlyToProps {
  flyTo: FlyTo
  map: Map
  padding?: { top: number; right: number; bottom: number; left: number }
}

export function handleFlyTo({ flyTo, map }: FlyToProps) {
  map.flyTo({
    center:
      flyTo.longitude && flyTo.latitude ? new LngLat(flyTo.longitude, flyTo.latitude) : undefined,
    zoom: flyTo.zoom ?? map.getZoom(),
    essential: flyTo.essential ?? false,
    pitch: flyTo.pitch ?? map.getPitch(),
    bearing: flyTo.bearing ?? map.getBearing(),
    padding: flyTo.padding,
    duration: flyTo.duration ?? 1500,
  })
}

export interface FitBoundsProps {
  boundingBox: [number, number, number, number]
  map: Map
  padding?: { top: number; right: number; bottom: number; left: number }
  usePadding?: boolean
}

export function handleFitBounds({ boundingBox, map, padding }: FitBoundsProps) {
  map.fitBounds(boundingBox, { duration: 1500, padding })
}

export function handleFlyToFeature({
  map,
  feature,
  flyToOptions,
}: {
  map: Map
  feature: GeoJSON.Feature
  flyToOptions?: Partial<FlyTo>
}) {
  const coords = getCenterOfFeature(feature as MapboxGeoJSONFeature)
  if (!coords) return
  handleFlyTo({ flyTo: { ...flyToOptions, longitude: coords[0], latitude: coords[1] }, map })
}
